/* eslint-disable complexity */
import React, { memo } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";
// Utils

import HomeLocationSelector from "../home-location-selector";

//Components
const HomeSearchSection = loadable(() => import("../home-search-section"), { ssr: true });

const HomeBannerSection = ({
    topBanner,
    source
}) => {

    const { additionalData = {} } = topBanner || {};
    const {
        backgroundGradients
    } = additionalData || {};
    const bannerInnerStyles = backgroundGradients ? { background: `linear-gradient(${backgroundGradients})` } : {};

    return (
        <React.Fragment>
            <div styleName={"styles.homeBannerSection"} style={bannerInnerStyles} >
                <HomeLocationSelector source={source}/>
                <HomeSearchSection />
            </div>
        </React.Fragment>
    );
};

HomeBannerSection.propTypes = {
    topBanner: PropTypes.object,
    source: PropTypes.string
};

export default memo(HomeBannerSection);
