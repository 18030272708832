import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HomeBannerSection from "./component";
import { clearAllFilters, setSearchResultTrackingData, updateAppliedFilters, updateFilterOptionData } from "../filters/actions";
import { reloadCarList } from "../car-listing/actions";

const mapStateToProps = ({
    cities: {
        selectedCity: {
            name: cityName,
            code
        }
    },
    config: {
        showMultiHubBanner,
        superAppConfig: {
            topBanner
        } = {}
    },
    user: {
        isLoggedIn
    },
    filters: {selectedFilters, allFilters}
}) => ({
    cityName,
    code,
    showMultiHubBanner, isLoggedIn, selectedFilters, allFilters, topBanner
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearAllFiltersConnect: clearAllFilters,
    setSearchResultTrackingDataConnect: setSearchResultTrackingData,
    updateFilterOptionDataConnect: updateFilterOptionData,
    updateAppliedFiltersConnect: updateAppliedFilters,
    reloadCarListConnect: reloadCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeBannerSection);
