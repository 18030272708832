import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { DEFAULT_CITY, LOCATION_SOURCE, NUMBER } from "../../../constants/app-constants";
import PageLoader from "../../shared/page-loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ROUTE_NAME } from "../../../constants/routes-constants";

const HomeLocationSelector = ({ selectedCityName, locationConfig, source = ROUTE_NAME.HOME }) => {
    const history = useHistory();
    const [showPageLoader, setShowPageLoader] = useState(false);
    const {
        textColor = "",
        locationIcon = "",
        caretDownIcon = ""
    } = locationConfig || {};

    const onChangeLocation = async () => {
        if (source === ROUTE_NAME.LISTING) {
            history.push(`/location-capture?from=${LOCATION_SOURCE.LISTING}`);
        } else {
            setShowPageLoader(true);
            await yieldToMainThread(NUMBER.FIVE_HUNDRED);
            history.push(`/location-capture/`);
        }
        await yieldToMainThread();
    };
    const inlineStyles = {
        color: textColor
    };

    return (
        <Fragment>
            <div styleName={"styles.locationWrapper"}>
                <div onClick={onChangeLocation}>
                    {locationIcon && <img src={locationIcon} styleName={"styles.locationIcon"} alt={"location_icon"} />}
                    <span style={inlineStyles}> {selectedCityName || DEFAULT_CITY.AE.city_name}</span>
                    {caretDownIcon && <img src={caretDownIcon} styleName={"styles.caretDownIcon"} alt={"arrow_down"} />}
                </div>
            </div>
            {showPageLoader && <PageLoader />}
        </Fragment>
    );
};

HomeLocationSelector.propTypes = {
    selectedCityName: PropTypes.string,
    locationConfig: PropTypes.object,
    source: PropTypes.string
};

export default HomeLocationSelector;

