import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import HomeLocationSelector from "./component";

const mapStateToProps = ({
    cities: {
        selectedCity: {
            name: selectedCityName
        } = {}
    },
    config: {
        superAppConfig: {
            topBanner: {
                location: locationConfig
            } = {}
        } = {}
    }
}) => ({
    locationConfig,
    selectedCityName
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeLocationSelector);
